///////////// LAYOUT EXAMPLE
////////////////////////////
// * form structured
//  <form class="field">
//  <div class="field__form">
//    <div class="field__group">
//      <input class="field__input">
//      <label class="field__label">
//          Lorem Ipsum
//          <span class="field__right-label">Obrigatório</span>
//      </label>
//    </div>
//    <div class="field__row">
//      <div class="field__group">
//        <label class="field__label"> Lorem Ipsum </label>
//        <input class="field__input">
//      </div>
//      <div class="field__group field__group--icon-left">
//        <input class="field__input">
//        <label class="field__label"> Lorem Ipsum </label>
//        <fa-icon class="material-icons-outlined">calendar_today</fa-icon>
//      </div>
//    </div>
//    <div class="field__group  field__group--center">
//      <button class="btn"></button>
//    </div>
//    <div class="field__group">
//      <p class="field__message field__message--error field__message--sucess"> Lorem Ipsum </p>
//    </div>
//    <mat-autocomplete #auto="matAutocomplete">
//      <mat-option *ngFor="let name of ['Nome 1', 'Nome 2', 'Nome 3', 'Nome 4', 'Nome 5',
//                                      'Nome 6', 'Nome 7', 'Nome 8', 'Nome 9', 'Nome 10']"
//                  [value]="name">
//          {{name}}
//      </mat-option>
//      <button (click)="openAddExampleModal()"
//              class="btn btn--lg btn--outline btn--icon-text">
//          <fa-icon>add</fa-icon>
//          Cadastrar novo
//      </button>
//    </mat-autocomplete>
//    <mat-chip-list #chipList>
//      <mat-chip *ngFor="let name of ['teste 1', 'teste 2', 'teste 3', 'teste 4']"
//                [removable]="true"
//                (removed)="remove(name)">
//          {{name}}
//          <button class="btn btn--sm btn--icon btn--no-bg"
//                  (click)="remove(name)">
//              <fa-icon>cancel</fa-icon>
//          </button>
//      </mat-chip>
//    </mat-chip-list>
//    <mat-slide-toggle class="field__toggle">
//    </mat-slide-toggle>
//    </div>
//  </form>

@import './variables/buttons-form';
@import './breakpoints';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  box-sizing: border-box;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.chip-list {
  width: 100%;
}

.field {
  width: 100%;

  + .field {
    margin-top: rem(16px);
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    gap: rem(16px);
  }

  &__row {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-end;
    gap: rem(12.5px);
    width: 100%;
  }

  &__slider.mat-slider {
    height: 20px;
    width: 100%;
    min-width: unset;
    padding: 0;
    padding-left: 10px;

    .mat-slider-wrapper {
      position: relative;
      top: 50%;
      right: unset;
      left: unset;
      bottom: unset;
      transform: translateY(-50%);
      height: 8px;
      border-radius: 99px;
    }
    .mat-slider-track-wrapper {
      height: inherit;
      border-radius: inherit;
    }
    .mat-slider-track-background {
      height: inherit;
      background-color: var(--mep-color-neutral-80) !important;
      border-radius: inherit;
    }
    .mat-slider-track-fill {
      height: inherit;
      border-radius: inherit;
      background-color: var(--mep-color-neutral-70) !important;
    }
    .mat-slider-thumb {
      transform: scale(1) !important;
      background-color: var(--mep-color-primary) !important;
      border-color: var(--mep-color-primary) !important;
    }
  }
}

.field__group {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  color: var(--mep-color-neutral-40);

  &--icon-right,
  &--icon-left {
    fa-icon {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: calc(50% - 7px);
      color: inherit;
      font-size: $btn-icon-size;
    }
  }

  &--icon-right {
    fa-icon {
      right: $btn-padding-right;
    }

    .field__input {
      padding-right: $btn-icon-padding;
    }
  }

  &--icon-left {
    fa-icon {
      left: $btn-padding-right;
    }

    .field__input {
      padding-right: $btn-icon-padding;
    }
  }

  &--center {
    align-items: center;
    justify-content: center;
  }
}

.field__label {
  display: flex;
  align-items: center;
  gap: rem(8px);
  width: 100%;
  color: var(--mep-color-neutral-30);
  font-size: $btn-font-size;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.04em;
  margin-bottom: rem(4px);

  &--sm {
    font-size: $btn-font-size--sm;
  }

  &--lg {
    font-size: $btn-font-size--lg;
  }
}

.field__right-label {
  font-weight: 400;
  font-size: 0.75rem;
  align-self: end;
  margin-top: rem(8px);
  font-size: rem(12px);

  &--error {
    color: var(--mep-color-error-40);
  }
}

.field__help {
  display: flex;
  align-items: center;
  justify-content: center;
  height: rem(18px) !important;
  width: rem(18px) !important;
  font-size: rem(18px) !important;
  line-height: 0.8 !important;
  color: inherit !important;
}

.field__input-caption {
  display: block;
  color: var(--mep-color-neutral-50);
  font-size: $btn-font-size--sm;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.02em;
  margin-top: rem(8px);

  &--error {
    color: var(--mep-color-error-40);
  }
}

.field__input {
  position: relative;
  display: flex !important;
  align-items: center;
  width: 100%;
  font-size: $btn-font-size;
  font-weight: 400;
  line-height: $btn-line-height;
  height: $btn-height;
  padding-top: $btn-padding-top;
  padding-bottom: $btn-padding-bottom;
  padding-right: $btn-padding-bottom;
  padding-left: $btn-padding-bottom;
  color: var(--mep-color-neutral-14);
  background-color: var(--mep-color-neutral-100);
  outline: 4px solid transparent;
  border: 1px solid;
  border-width: $btn-border-width;
  border-radius: $btn-border-radius;
  border-color: var(--mep-color-neutral-80);
  transition: all 0.3s ease;
  font-family: var(--font-primary);

  &--sm {
    font-size: $btn-font-size--sm;
    height: $btn-height--sm;
    letter-spacing: $btn-letter-spacing--sm;
    font-weight: $btn-font-weight--sm;
    padding-top: $btn-padding-top--sm;
    padding-bottom: $btn-padding-bottom--sm;
    padding-right: $btn-padding-bottom--sm;
    padding-left: $btn-padding-bottom--sm;
  }

  &--lg {
    font-size: $btn-font-size--lg;
    line-height: $btn-line-height--lg;
    height: $btn-height--lg;
    padding-top: $btn-padding-top--lg;
    padding-bottom: $btn-padding-bottom--lg;
    padding-right: $btn-padding-bottom--lg;
    padding-left: $btn-padding-bottom--lg;
  }

  @at-root {
    textarea#{&} {
      min-height: 100px;
      resize: none;
    }

    #{&}.mat-select,
    select#{&} {
      padding: 0;
      color: var(--mep-color-neutral-40);
      background-color: var(--mep-color-neutral-100);
      position: relative;

      .mat-select-trigger {
        padding-top: $btn-padding-top;
        padding-bottom: $btn-padding-bottom;
        padding-right: $btn-padding-right;
        padding-left: $btn-padding-left;
      }

      span {
        font-size: $btn-font-size;
        line-height: $btn-line-height;
      }
    }
  }
}

.mat-option {
  font-size: $btn-font-size;
  font-weight: 400;
  line-height: $btn-line-height;
  color: var(--mep-color-neutral-20);
  transition: all 250ms ease !important;

  &.mat-active {
    background: var(--mep-color-primary) !important;
    color: var(--mep-color-white) !important;
  }
}

.mat-select-value-text {
  font-size: $btn-font-size;
  font-weight: 400;
  line-height: $btn-line-height;
  color: var(--mep-color-neutral-20);
}

.mat-select {
  display: flex !important;
  align-items: center;
  outline: 4px solid transparent !important;

  &:focus {
    outline: 4px solid var(--mep-color-secondary) !important;
  }
}

.field__input {
  &::placeholder {
    font-size: $btn-font-size;
    font-weight: 500;
    color: var(--mep-color-neutral-70);

    @include breakpoint-max(sm) {
      font-weight: 400;
    }
  }

  &:focus {
    background-color: var(--mep-color-neutral-100);
    border-color: var(--mep-color-neutral-50);
    outline-color: var(--mep-color-neutral-80) !important;
  }

  &:hover {
    border-color: var(--mep-color-neutral-50);
  }

  &.ng-valid.ng-dirty:not(:disabled),
  &--valid:not(:disabled) {
    border-color: var(--mep-color-neutral-50);
    outline-color: transparent;
  }

  &.ng-invalid.ng-dirty:not(:disabled),
  &--invalid:not(:disabled) {
    color: var(--mep-color-error-40);
    border-color: var(--mep-color-error-70);
    outline-color: var(--mep-color-error-90);
  }

  @at-root {
    &#{&}:not(mat-select):not(select):not(.pac-container):not(span):read-only,
    &#{&}:not(mat-select):not(select):not(.pac-container):not(span):disabled,
    &#{&}.mat-select[aria-disabled~='true'],
    &#{&}--disabled,
    select#{&}[aria-disabled~='true'] {
      background-color: var(--mep-color-neutral-95) !important;
      border-color: var(--mep-color-neutral-95) !important;
      outline: unset;
      pointer-events: none !important;

      &::placeholder {
        color: var(--mep-color-neutral-80) !important;
      }
    }
  }
}

.mat-select-trigger {
  width: inherit;
}

.mat-select-arrow {
  color: var(--mep-color-neutral-40);
}

.mat-autocomplete-panel .mat-option:focus:not(.mat-option-disabled),
.mat-autocomplete-panel .mat-option:hover:not(.mat-option-disabled),
.mat-select-panel .mat-option:hover {
  background-color: var(--mep-color-neutral-80);
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple),
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background-color: var(--mep-color-primary);
}

.mat-select-placeholder,
.mat-select-value {
  font-size: rem(14px);
  font-weight: 500;
  color: var(--mep-color-neutral-80);
  font-family: var(--font-primary);

  @include breakpoint-max(sm) {
    font-weight: 400;
  }
}

.mat-option-text {
  font-family: var(--font-primary);
}

.mat-autocomplete-panel,
.mat-select-panel {
  background-color: var(--mep-color-neutral-95);
  min-width: calc(100% + 16px) !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  border-color: var(--mep-color-success-40) !important;
  background-color: var(--mep-color-success-40) !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  border-color: var(--mep-color-success-40) !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  background-color: var(--mep-color-neutral-90) !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: var(--mep-color-success-40) !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
  background-color: var(--mep-color-success-80) !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:focus:checked
  ~ .mdc-checkbox__ripple {
  background-color: var(--mep-color-success-80) !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:focus:checked
  ~ .mdc-checkbox__ripple {
  background-color: var(--mep-color-success-80) !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: var(--mep-color-neutral-95) !important;
}

//toogle switch material edited
.field__toggle {
  $toggle-height: rem(40px);
  $toggle-width: rem(80px);
  $toggle-size-thumb: rem(32px);
  $toggle-top-thumb: rem(4px);
  $toggle-left-thumb: rem(4px);

  &.mat-slide-toggle {
    height: $toggle-height !important;
  }
  .mat-slide-toggle-content {
    font-size: rem(16px);
    font-weight: 500;
    font-family: var(--font-primary);
    color: var(--mep-color-neutral-30);
    letter-spacing: 0.04em;
  }

  .mat-slide-toggle-bar {
    height: rem(40px) !important;
    width: rem(80px) !important;
    border-radius: rem(56px) !important;
    background: var(--mep-color-neutral-80);
  }

  .mat-slide-toggle-bar {
    height: $toggle-height !important;
    width: $toggle-width !important;
    border-radius: rem(56px) !important;
    background: var(--mep-color-neutral-80);
  }

  .mat-slide-toggle-thumb {
    height: $toggle-size-thumb !important;
    width: $toggle-size-thumb !important;
    background: var(--mep-color-neutral-100);
  }

  .mat-slide-toggle-thumb-container {
    height: $toggle-size-thumb !important;
    width: $toggle-size-thumb !important;
    top: $toggle-top-thumb !important;
    left: $toggle-left-thumb !important;
  }

  &.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(38px, 0, 0) !important;
  }

  &.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: var(--mep-color-primary) !important;
  }

  &--sm {
    $toggle-height: rem(24px);
    $toggle-width: rem(48px);
    $toggle-size-thumb: rem(18px);
    $toggle-position-thumb: 22px;
    $toggle-top-thumb: rem(3px);
    $toggle-left-thumb: rem(3px);

    .mat-slide-toggle {
      height: $toggle-height !important;
    }

    .mat-slide-toggle-bar {
      height: $toggle-height !important;
      width: $toggle-width !important;
      border-radius: rem(56px) !important;
      background: var(--mep-color-neutral-80);
    }

    .mat-slide-toggle-thumb {
      height: $toggle-size-thumb !important;
      width: $toggle-size-thumb !important;
      background: var(--mep-color-neutral-100);
    }

    .mat-slide-toggle-thumb-container {
      height: $toggle-size-thumb !important;
      width: $toggle-size-thumb !important;
      top: $toggle-top-thumb !important;
      left: $toggle-left-thumb !important;
    }

    &.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
      transform: translate3d(22px, 0, 0) !important;
    }

    &.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
      background-color: var(--mep-color-primary) !important;
    }
  }
}

//end //toogle switch material edited

.field__message {
  display: block;
  width: 100%;
  font-size: $btn-font-size * 0.9;
  line-height: $btn-line-height * 0.9;
  padding-top: $btn-padding-top * 0.9;
  padding-bottom: $btn-padding-bottom * 0.9;
  padding-right: $btn-padding-right * 0.9;
  padding-left: $btn-padding-left * 0.9;
  color: var(--mep-color-neutral-100);
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.04em;
  border-radius: 6px;
  background-color: var(--mep-color-neutral-40);

  &--success {
    background-color: var(--mep-color-success-40);
  }

  .fa-icon {
    color: inherit;
  }
}

.field__updated-message {
  padding: rem(14px) rem(32px);
  opacity: 0;
  font-size: 12px;
  font-weight: bold;
  color: var(--mep-color-primary);

  &--show-hide {
    animation: jumpEaseIn 5s;
  }
}

.field__input:-webkit-autofill,
.field__input:-webkit-autofill:hover,
.field__input:-webkit-autofill:focus,
.field__input:-webkit-autofill:active {
  // Remove background from chrome autocomplete
  // https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
  transition: 'color 9999s ease-out, background-color 9999s ease-out' !important;
  transition-delay: 9999s !important;
}

.field__group {
  $blockname: &;

  &--sm {
    &#{$blockname} {
      &--icon-right,
      &--icon-left {
        fa-icon {
          font-size: $btn-icon-size;
          line-height: $btn-icon-size;
          width: $btn-icon-size;
          height: $btn-height--sm;
        }
      }

      &--icon-right {
        fa-icon {
          right: $btn-padding-bottom--sm;
        }

        .field__input {
          padding-right: $btn-icon-padding !important;
        }
      }

      &--icon-left {
        fa-icon {
          left: $btn-padding-bottom--sm;
        }

        .field__input {
          padding-left: $btn-icon-padding !important;
        }
      }

      .field__label {
        font-size: $btn-font-size--sm;
      }

      .field__input {
        font-size: $btn-font-size--sm;
        height: $btn-height--sm;
        letter-spacing: $btn-letter-spacing--sm;
        font-weight: $btn-font-weight--sm;
        padding-top: $btn-padding-top--sm;
        padding-bottom: $btn-padding-bottom--sm;
        padding-right: $btn-padding-bottom--sm;
        padding-left: $btn-padding-bottom--sm;

        &::placeholder {
          font-size: $btn-font-size--sm;
          font-weight: 500;

          @include breakpoint-max(sm) {
            font-weight: 400;
          }
        }
      }
    }
  }

  &--lg {
    &#{$blockname} {
      &--icon-right,
      &--icon-left {
        fa-icon {
          font-size: $btn-icon-size--lg;
          line-height: $btn-icon-size--lg;
          width: $btn-icon-size--lg;
          height: $btn-height--lg;
        }
      }

      &--icon-right {
        fa-icon {
          right: $btn-padding-right;
        }

        .field__input {
          padding-right: $btn-icon-padding !important;
        }
      }

      &--icon-left {
        fa-icon {
          left: $btn-padding-right;
        }

        .field__input {
          padding-left: $btn-icon-padding !important;
        }
      }

      .field__label {
        font-size: $btn-font-size--lg;
      }

      .field__input {
        font-size: $btn-font-size--lg;
        height: $btn-height--lg;
        padding-top: $btn-padding-top--lg;
        padding-bottom: $btn-padding-bottom--lg;
        padding-right: $btn-padding-bottom--lg;
        padding-left: $btn-padding-bottom--lg;

        &::placeholder {
          font-size: $btn-font-size--lg;
          font-weight: 500;

          @include breakpoint-max(sm) {
            font-weight: 400;
          }
        }
      }
    }
  }
}

@keyframes jumpEaseIn {
  0% {
    opacity: 0;
    transform: translateY(0px);
  }
  3% {
    opacity: 1;
    transform: translateY(0px);
  }
  5% {
    transform: translateY(-14px);
  }
  8% {
    transform: translateY(0px);
  }
  9% {
    transform: translateY(-5px);
  }
  10% {
    transform: translateY(0px);
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
