// ? visual information to understand BUTTON SYSTEM   https://www.instagram.com/p/CKWkT_CDa-g/

// ! AVAILABLE STYLES

// * style default
// .btn

// * STYLE MODIFIERS
// .btn.btn--full-width
// .btn.btn--outline
// .btn.btn--no-bg
// .btn.btn--icon                               - for button that contains only icon without text
// .btn.btn--icon-text                          - for button that contains icon with text

// * SIZE MODIFIERS
// .btn.btn--ssm                                  - matches with all modifiers
// .btn.btn--sm                                  - matches with all modifiers
// .btn.btn--lg                                  - matches with all modifiers

// * ATTRIBUTE MODIFIERS
// .btn:disabled   //.btn.btn--disabled         - sane result, but using class, not attribute

// * MATCH EXAMPLES  - "MIX AND MATCH"
// .btn.btn--outline.btn-icon
// .btn.btn-lg.btn--no-bg.btn--icon
// .btn:disable.btn--outline
// .btn.btn--disable.btn--outline

@import './variables/buttons-form';
@import './variables/breakpoints';

// DEFAULT BLACK // DEFAULT BLACK // DEFAULT BLACK // DEFAULT BLACK
.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $btn-font-size;
  line-height: $btn-line-height;
  letter-spacing: $btn-letter-spacing;
  font-weight: $btn-font-weight;
  text-align: center;
  text-transform: $btn-text-transform;
  text-decoration: none;
  white-space: nowrap;
  height: $btn-height;
  min-width: $btn-height;
  padding-top: $btn-padding-top;
  padding-bottom: $btn-padding-bottom;
  padding-right: $btn-padding-right;
  padding-left: $btn-padding-left;
  width: fit-content;
  border: 1px solid;
  gap: rem(6px);
  border-width: $btn-border-width;
  border-radius: $btn-border-radius;
  transition: all 0.3s ease;
  font-family: var(--font-primary);
  color: var(--mep-color-neutral-100);
  border-color: var(--mep-color-primary);
  background-color: var(--mep-color-primary);
  cursor: pointer;

  span {
    font-size: $btn-font-size;
  }

  &:hover {
    border-color: var(--mep-color-hover-button);
    background-color: var(--mep-color-hover-button);
  }

  &:active {
    border-color: var(--mep-color-active-button);
    background-color: var(--mep-color-active-button);
  }

  &--sm {
    font-size: $btn-font-size--sm;
    height: $btn-height--sm;
    letter-spacing: $btn-letter-spacing--sm;
    font-weight: $btn-font-weight--sm;
    padding-top: $btn-padding-top--sm;
    padding-bottom: $btn-padding-bottom--sm;
    padding-right: $btn-padding-right--sm;
    padding-left: $btn-padding-left--sm;

    span {
      font-size: $btn-font-size--sm;
    }
  }

  &--ssm {
    font-size: $btn-font-size--ssm;
    height: $btn-height--ssm;
    letter-spacing: $btn-letter-spacing--ssm;
    font-weight: $btn-font-weight--ssm;
    padding-top: $btn-padding-top--ssm;
    padding-bottom: $btn-padding-bottom--ssm;
    padding-right: $btn-padding-right--ssm;
    padding-left: $btn-padding-left--ssm;

    span {
      font-size: $btn-font-size--ssm;
    }
  }

  &--lg {
    font-size: $btn-font-size--lg;
    line-height: $btn-line-height--lg;
    height: $btn-height--lg;
    padding-top: $btn-padding-top--lg;
    padding-bottom: $btn-padding-bottom--lg;
    padding-right: $btn-padding-right--lg;
    padding-left: $btn-padding-left--lg;

    span {
      font-size: $btn-font-size--lg;
    }
  }
}

.btn--full-width {
  width: 100%;
}

.btn--outline {
  background-color: transparent;
  border-color: var(--mep-color-primary-font);
  color: var(--mep-color-primary-font);

  &:hover {
    background-color: var(--mep-color-hover-button-outline);
    color: var(--mep-color-neutral-100);
  }

  &:active {
    background-color: var(--mep-color-active-button-outline);
    color: var(--mep-color-neutral-100);
  }
}

.btn--no-bg {
  background-color: transparent;
  border-color: transparent;
  color: var(--mep-color-primary-font);
  border: none;
  display: inline-flex;
  padding: 0 !important;

  &:hover {
    background-color: transparent;
    color: var(--mep-color-neutral-40);
  }
}

.btn--icon-text {
  gap: $btn-icon-gap;

  .mat-icon.material-icons-outlined,
  .mat-icon.material-icons {
    color: inherit;
    font-size: $btn-icon-size;
    line-height: $btn-icon-size;
    width: $btn-icon-size;
    height: $btn-icon-size;
  }

  &.btn--lg {
    gap: $btn-icon-gap--lg;

    .mat-icon.material-icons-outlined,
    .mat-icon.material-icons {
      font-size: $btn-icon-size--lg;
      line-height: $btn-icon-size--lg;
      width: $btn-icon-size--lg;
      height: $btn-icon-size--lg;
    }
  }

  &.btn--sm {
    gap: $btn-icon-gap--sm;
  }

  &.btn--ssm {
    gap: $btn-icon-gap--ssm;
  }
}

.btn--icon {
  gap: $btn-icon-gap;
  min-width: $btn-height;
  padding: 0;

  .mat-icon.material-icons-outlined,
  .mat-icon.material-icons {
    color: inherit;
    font-size: $btn-icon-size;
    line-height: $btn-icon-size;
    width: $btn-icon-size;
    height: $btn-icon-size;
  }

  &.btn--lg {
    height: $btn-height--lg;
    gap: $btn-icon-gap--lg;
    min-width: $btn-height--lg;

    .mat-icon.material-icons-outlined,
    .mat-icon.material-icons {
      font-size: $btn-icon-size--lg;
      line-height: $btn-icon-size--lg;
      width: $btn-icon-size--lg;
      height: $btn-icon-size--lg;
    }
  }

  &.btn--sm {
    height: $btn-height--sm;
    gap: $btn-icon-gap--sm;
    min-width: $btn-height--sm;
  }

  &.btn--ssm {
    height: $btn-height--ssm;
    gap: $btn-icon-gap--ssm;
    min-width: $btn-height--ssm;
  }
}

.btn:disabled,
.btn--disabled {
  pointer-events: none;
  filter: contrast(0.8);
}

.btn--sm.btn--icon {
  .mat-icon.material-icons-outlined,
  .mat-icon.material-icons {
    font-size: $btn-icon-size;
    width: $btn-icon-size;
    height: $btn-icon-size;
  }
}

.btn--ssm.btn--icon {
  .mat-icon.material-icons-outlined,
  .mat-icon.material-icons {
    font-size: $btn-icon-size--ssm;
    width: $btn-icon-size--ssm;
    height: $btn-icon-size--ssm;
  }
}
// PRIMARY // PRIMARY // PRIMARY // PRIMARY // PRIMARY // PRIMARY //
.btn--primary {
  color: var(--mep-color-neutral-100);
  border-color: var(--mep-color-primary);
  background-color: var(--mep-color-primary);
}

.btn--primary.btn--outline {
  background-color: transparent;
  color: var(--mep-color-primary);
  border-color: var(--mep-color-color-plus-2);
}

.btn--primary.btn--no-bg {
  background-color: transparent;
  color: var(--mep-color-primary);
}

.btn--primary.btn:disabled,
.btn--primary.btn--disabled {
  background-color: var(--mep-color-neutral-80);
  border-color: var(--mep-color-neutral-80);
  color: var(--mep-color-neutral-70);
  pointer-events: none;

  &.btn--outline,
  &.btn--outline {
    background-color: transparent;
    color: var(--mep-color-neutral-70);
    border-color: var(--mep-color-neutral-70);
  }
}

// ERROR // ERROR // ERROR // ERROR // ERROR // ERROR //
.btn--error {
  color: var(--mep-color-neutral-100);
  border-color: var(--mep-color-error-40);
  background-color: var(--mep-color-error-40);
}

.btn--error.btn--outline {
  background-color: transparent;
  color: var(--mep-color-error-40);
}
.btn--error.btn--no-bg {
  background-color: transparent;
  color: var(--mep-color-error-40);
}

.mat-mdc-progress-spinner {
  width: 18px !important;
  height: 18px !important;
  --mdc-circular-progress-active-indicator-color: var(
    --mep-color-negative-font
  ) !important;

  .btn--outline,
  .btn--no-bg {
    --mdc-circular-progress-active-indicator-color: var(
      --mep-color-primary
    ) !important;
  }
}

.mat-autocomplete-panel {
  .option-button {
    margin: 12px auto;

    &:hover {
      background-color: transparent !important;
    }

    .mat-option-text {
      display: flex !important;
      justify-content: center !important;
    }
  }

  .btn {
    gap: 0;
  }
}
