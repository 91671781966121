// * for those who want to have a better understand over dark/light mode and iots relevance on user experience https://www.youtube.com/watch?v=HxJnvCOC2vQ
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

html {
  color-scheme: light dark;
  @include mat.theme(
    (
      color: (
        primary: mat.$violet-palette,
        tertiary: mat.$orange-palette,
      ),
      typography: Roboto,
      density: 0,
    )
  );
}

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

:root {
  //strip H S L into variables hsl(192, 80%, 35%);
  --debugg: hsl(192, 80%, 35%);
  --mep-color-h: 195;
  --mep-color-s: 80%;
  --mep-color-l: 35%;

  --mep-color-primary: #4e92ce;
  --mep-color-white: white;
  --mep-color-black: black;

  --mep-color-negative: var(--mep-color-neutral-20);
  --mep-color-negative-font: var(--mep-color-neutral-90);
  --mep-color-primary-font: var(--mep-color-neutral-20);
  --mep-color-secondary-font: var(--mep-color-neutral-50);
  --mep-color-secondary: #4ecec3;

  --mep-color-hover-button: #4683b9;
  --mep-color-active-button: #3e75a5;

  --mep-color-hover-button-outline: #22194d;
  --mep-color-active-button-outline: #1a143e;

  --mep-color-neutral-100: #ffffff;
  --mep-color-neutral-95: #fafafa;
  --mep-color-neutral-90: #f5f5f5;
  --mep-color-neutral-80: #e6e6e6;
  --mep-color-neutral-70: #cccccc;
  --mep-color-neutral-60: #adadad;
  --mep-color-neutral-50: #808080;
  --mep-color-neutral-40: #616161;
  --mep-color-neutral-30: #363636;
  --mep-color-neutral-20: #212121;
  --mep-color-neutral-10: #171717;

  --mep-color-info: #5abfdd;
  --mep-color-blue: #24acd6;
  --mep-color-red: #d04971;
  --mep-color-purple: #b549d0;
  --mep-color-yellow: #ddd95a;
  --mep-color-orange: #dd955a;

  --mep-color-success-100: hsl(146, 25%, 97%);
  --mep-color-success-90: hsl(146, 25%, 93%);
  --mep-color-success-80: #c8ead6;
  --mep-color-success-70: #a3dcbb;
  --mep-color-success-40: #12a150;

  --mep-color-backdrop: rgba(0, 0, 0, 0.4);

  --mep-color-error-90: #f2e9ea;
  --mep-color-error-80: #eac8ce;
  --mep-color-error-70: #dca3ae;
  --mep-color-error-50: #d77588;
  --mep-color-error-40: #a1122e;

  --mep-color-plus-6: hsl(var(--mep-color-h), 25%, 97%);
  --mep-color-plus-5: hsl(var(--mep-color-h), 25%, 93%);
  --mep-color-plus-4: hsl(var(--mep-color-h), 45%, 85%);
  --mep-color-plus-3: hsl(var(--mep-color-h), 45%, 75%);
  --mep-color-plus-2: hsl(var(--mep-color-h), 50%, 65%);
  --mep-color-plus-1: hsl(var(--mep-color-h), 55%, 56%);
  --mep-color-minus-1: hsl(var(--mep-color-h), 85%, 25%);
  --mep-color-minus-2: hsl(var(--mep-color-h), 90%, 15%);
  --mep-color-minus-3: hsl(var(--mep-color-h), 95%, 10%);

  //material colors
  --mat-menu-container-color: var(--mep-color-neutral-100);
  --mat-expansion-container-background-color: var(--mep-color-neutral-100);

  @media (prefers-color-scheme: dark) {
    .logo {
      filter: grayscale(1) invert(1);
    }

    img {
      opacity: 0.75;
      transition: opacity 0.5s ease-in-out;
    }

    img:hover {
      opacity: 1;
    }

    --mep-color-neutral-10: hsl(0, 0%, 100%);
    --mep-color-neutral-20: hsl(0, 0%, 98%);
    --mep-color-neutral-30: hsl(0, 0%, 96%);
    --mep-color-neutral-40: hsl(0, 0%, 90%);
    --mep-color-neutral-50: hsl(0, 0%, 80%);
    --mep-color-neutral-60: hsl(0, 0%, 68%);
    --mep-color-neutral-70: hsl(0, 0%, 50%);
    --mep-color-neutral-80: hsl(0, 0%, 38%);
    --mep-color-neutral-90: hsl(0, 0%, 21%);
    --mep-color-neutral-95: hsl(0, 0%, 13%);
    --mep-color-neutral-100: hsl(0, 0%, 9%);

    //survey js Colors
    --background-dim: none;

    .sd-item--disabled .sd-item__control-label {
      opacity: 0.75;
    }
  }
}
