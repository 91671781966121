/* You can add global styles to this file, and also import other style files */

@import 'styles/default';

html,
body {
  height: 100%;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
