//  REGULAR SIZE  //  REGULAR SIZE  //  REGULAR SIZE  //
//  REGULAR SIZE  //  REGULAR SIZE  //  REGULAR SIZE  //

//font
$btn-font-size: rem(14px);
$btn-line-height: rem(20px);
$btn-letter-spacing: 0.02em;
$btn-font-weight: 600;
$btn-text-transform: unset;
//size
$btn-height: rem(56px);

//padding
$btn-padding-top: rem(14px);
$btn-padding-bottom: rem(14px);
$btn-padding-right: rem(16px);
$btn-padding-left: rem(16px);
//borders
$btn-border-width: 1px;
$btn-border-radius: rem(6px);
//icons
$btn-icon-size: rem(14px);
$btn-icon-gap: rem(4px);

//  SMALL SIZE  //  SMALL SIZE  //  SMAL SIZE  //
//  SMALL SIZE  //  SMALL SIZE  //  SMAL SIZE  //

//font
$btn-font-size--sm: 14px;
$btn-letter-spacing--sm: 0.02em;
$btn-font-weight--sm: 500;
//size
$btn-height--sm: rem(40px);
//padding
$btn-padding-top--sm: rem(10px);
$btn-padding-bottom--sm: rem(10px);
$btn-padding-right--sm: rem(16px);
$btn-padding-left--sm: rem(16px);
//icons
$btn-icon-gap--sm: rem(8px);

//  LARGE SIZE  //  LARGE SIZE  //  LARGE SIZE  //
//  LARGE SIZE  //  LARGE SIZE  //  LARGE SIZE  //

//font
$btn-font-size--lg: rem(18px);
$btn-line-height--lg: rem(24px);
//size
$btn-height--lg: rem(56px);
//padding
$btn-padding-top--lg: rem(16px);
$btn-padding-bottom--lg: rem(16px);
$btn-padding-right--lg: rem(24px);
$btn-padding-left--lg: rem(24px);
//icons
$btn-icon-size--lg: rem(16px);
$btn-icon-gap--lg: rem(12px);
$btn-icon-padding: rem(28px);
