@import './functions';
@import './colors';
@import './breakpoints';
@import './typography';
@import './buttons';
@import './forms';
@import './video-js';
@import './variables/screen';

html {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: var(--mep-color-neutral-100);
}

mec-dynamic-html {
  div,
  p,
  ul,
  li,
  figure,
  font {
    max-width: 100%;
  }

  li {
    margin-bottom: rem(12px);
  }

  p {
    margin-bottom: rem(24px);
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  cursor: pointer;
  text-decoration: underline;
}

img,
video {
  max-width: 100%;
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}

/* hide recaptcha badge*/
.grecaptcha-badge {
  visibility: hidden;
}

/* only show recaptcha badge on needed pages */
body.recaptcha .grecaptcha-badge {
  visibility: visible;
}

//mat-tab
.mdc-tab__text-label {
  gap: 12px;
}

//mat-accordion
.mat-expansion-panel-header {
  padding: 0 rem(12px) !important;
}

//mat-snack-bar (toast)
.mdc-snackbar__surface {
  background-color: var(--mep-color-neutral-80) !important;
}

.mat-expansion-panel {
  box-shadow: 0 5px 9px rgba(0, 0, 0, 0.05),
    0 2.2528px 9.0736px rgba(0, 0, 0, 0.04),
    0 9.3744px 16.5008px rgba(0, 0, 0, 0.03),
    0 3.7696px 5.2912px rgba(0, 0, 0, 0.02),
    0 0.8432px 4.4544px rgba(0, 0, 0, 0.01) !important;
}

//survey default styles
#surveyElement {
  .sd-file__list:has(img) {
    min-height: 100% !important;
    padding: 0 !important;
  }

  .sd-file__preview {
    width: 100%;
  }

  .sd-file__image-wrapper {
    width: 100%;
    height: 100%;

    img {
      height: 100% !important;
      width: 100% !important;
    }
  }
}

.nav-input {
  color: var(--mep-color-neutral-50) !important;
  background: transparent !important;
  box-shadow: none !important;

  &:hover {
    background: var(--mep-color-neutral-80) !important;
  }
}

//Skeleton styles
.skeleton {
  background: linear-gradient(
      0.25turn,
      transparent,
      var(--mep-color-neutral-100),
      transparent
    ),
    linear-gradient(var(--mep-color-neutral-80), var(--mep-color-neutral-80)),
    radial-gradient(
      38px circle at 19px 19px,
      var(--mep-color-neutral-80) 50%,
      transparent 51%
    ),
    linear-gradient(
      0.25turn,
      transparent,
      var(--mep-color-neutral-100),
      transparent
    );
  background-repeat: no-repeat;
  background-size: 100%;
  animation: skeleton-loading 1.5s infinite;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  pointer-events: none !important;
  color: transparent !important;
  transition: none !important;
  border-radius: 2px !important;
  user-select: none !important;

  * {
    opacity: 0 !important;
    color: transparent !important;
    transition: none !important;
  }

  &--dark {
    background: linear-gradient(
        0.25turn,
        transparent,
        var(--mep-color-neutral-20),
        transparent
      ),
      linear-gradient(var(--mep-color-neutral-30), var(--mep-color-neutral-30)),
      radial-gradient(
        38px circle at 19px 19px,
        var(--mep-color-neutral-30) 50%,
        transparent 51%
      ),
      linear-gradient(
        0.25turn,
        transparent,
        var(--mep-color-neutral-20),
        transparent
      );
  }
}

@keyframes skeleton-loading {
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}
//end Skeleton styles

.avatar {
  border-radius: 50%;
  width: rem(60px);
  height: rem(60px);
  object-fit: cover;
  box-shadow: 2px 4px 10px 1px var(--mep-color-neutral-60);
}

.card {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 6px;
  background-color: var(--mep-color-neutral-100);
  box-shadow: 0 5px 9px rgba(0, 0, 0, 0.05),
    0 2.2528px 9.0736px rgba(0, 0, 0, 0.04),
    0 9.3744px 16.5008px rgba(0, 0, 0, 0.03),
    0 3.7696px 5.2912px rgba(0, 0, 0, 0.02),
    0 0.8432px 4.4544px rgba(0, 0, 0, 0.01);
  transition: all 0.3s ease;
}

.line {
  border-bottom: solid 1px var(--mep-color-neutral-90);
}

.page-content {
  display: flex;
  gap: rem(48px);
  padding: rem(36px) rem(48px);

  @include breakpoint-min(xl) {
    padding: rem(48px) rem(96px);
  }

  @include breakpoint-min(xxl) {
    padding: rem(48px) rem(144px);
  }

  @include breakpoint-max(sm) {
    padding: rem(48px) rem(24px);
  }
}

.hidden {
  height: 0;
  width: 0;
  display: none;
}

.line-clamp-1,
.line-clamp-2,
.line-clamp-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: var(--mep-color-primary) !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__ripple::before,
.mat-mdc-tab.mdc-tab--active .mat-ripple-element {
  background-color: var(--mep-color-primary) !important;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: var(--mep-color-primary) !important;
}
